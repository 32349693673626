import React, { FC, useState } from 'react';
import { Configure } from 'react-instantsearch-dom';

import { Container } from 'layout';
import CustomCurrentRefinements from 'common/CustomCurrentRefinements';
import Filters from 'common/Filters';
import CustomHits from 'common/Filters/CustomHits';
import CustomResultsHandler from 'common/Filters/CustomResultsHandler';
import InstantSearchContainer from 'common/InstantSearchContainer';
import LayoutSwitcher from 'common/LayoutSwitcher';
import SortingHits from 'common/SortingHits';
import { ARTICLES_PER_LOAD_DEFAULT } from 'utils/constants';

import { ArticlesListingProps } from './models';
import { getNumberOfArticlesPerLoad } from './utils';

import './ArticlesListing.scss';

const ArticlesListing: FC<ArticlesListingProps> = ({
  pageId,
  lang,
  readingTimeText,
  writtenByText,
  numberOfArticlesPerLoad,
  searchPlaceholder,
  searchText,
  categoriesDropdownText,
  yearsDropdownText,
  selectedFiltersText,
  numberOfResultsText,
  clearFiltersText,
  sortByText,
  mostRecentSortText,
  oldestSortText,
  gridViewLabelText,
  listViewLabelText,
  loadMoreButtonText,
  noResultsText,
  popularTopics,
}) => {
  const [isGrid, setIsGrid] = useState<boolean>(true);
  const [cleared, setCleared] = useState<boolean>(false);

  const handleLayoutView = (value: boolean) => {
    setIsGrid(value);
  };

  const resetClear = () => {
    setCleared(false);
  };

  const clear = () => {
    setCleared(true);
  };

  return (
    <InstantSearchContainer
      indexName={`${process.env.GATSBY_ALGOLIA_INDEX}-articles`}
      cleared={cleared}
    >
      <Configure
        hitsPerPage={
          numberOfArticlesPerLoad
            ? getNumberOfArticlesPerLoad(numberOfArticlesPerLoad)
            : ARTICLES_PER_LOAD_DEFAULT
        }
        numericFilters={[`landingPageId = ${pageId}`]}
        filters={`lang: "${lang}"`}
      />
      <Filters
        searchBtnText={searchText}
        selectedFiltersText={selectedFiltersText}
        searchPlaceholder={searchPlaceholder}
        firstFilterText={categoriesDropdownText}
        secondFilterText={yearsDropdownText}
        filters={['articleTags.name', 'year']}
        numberOfResultsText={numberOfResultsText}
        clearFiltersText={clearFiltersText}
        popularTopics={popularTopics}
        clear={clear}
        resetClear={resetClear}
      />
      <Container>
        <div className="sort-by-and-layout">
          <SortingHits
            sortByText={sortByText}
            firstValue={mostRecentSortText}
            secondValue={oldestSortText}
          />
          <LayoutSwitcher
            onHandleLayoutView={(value) => handleLayoutView(value)}
            gridViewLabelText={gridViewLabelText}
            listViewLabelText={listViewLabelText}
          />
        </div>
        <CustomCurrentRefinements />
      </Container>
      <CustomResultsHandler noResultsText={noResultsText}>
        <CustomHits
          loadMoreText={loadMoreButtonText}
          isGridLayout={isGrid}
          lang={lang}
          readingTimeText={readingTimeText}
          writtenByText={writtenByText}
        />
      </CustomResultsHandler>
    </InstantSearchContainer>
  );
};

export default ArticlesListing;
