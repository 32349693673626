import React, { FC } from 'react';

import CtaCards from 'components/CtaCards';
import SingleCta from 'components/SingleCta';
import TitleAndDownload from 'components/TitleAndDownload';

import { InsightsLandingBodyRendererProps } from './models';

const Components = {
  'CTA Cards': CtaCards,
  'Single CTA': SingleCta,
  'Title And Download': TitleAndDownload,
};

const InsightsLandingBodyRenderer: FC<InsightsLandingBodyRendererProps> = ({ modules }) => (
  <>
    {modules?.map((block, index) => {
      if (typeof Components[block.structure] === 'undefined') {
        return null;
      }

      const Component = Components[block.structure];

      return <Component key={`${block.structure}${index}`} module={block.module} />;
    })}
  </>
);

export default InsightsLandingBodyRenderer;
