import React, { FC, useState } from 'react';
import classNames from 'classnames';

import GridView from '../icons/grid-view.svg';
import ListView from '../icons/list-view.svg';
import { LayoutSwitcherProps } from './models';

import './LayoutSwitcher.scss';

const LayoutSwitcher: FC<LayoutSwitcherProps> = ({
  onHandleLayoutView,
  gridViewLabelText,
  listViewLabelText,
}) => {
  const [isGridView, setIsGridView] = useState<boolean>(true);

  const handleGridView = () => {
    setIsGridView(true);
    onHandleLayoutView(true);
  };

  const handleListView = () => {
    setIsGridView(false);
    onHandleLayoutView(false);
  };

  return (
    <div className="layout-switcher">
      <div className="layout-switcher__holder">
        <button
          className={classNames('layout-switcher__btn', {
            'layout-switcher__btn--active': isGridView,
          })}
          type="button"
          aria-label={gridViewLabelText}
          onClick={handleGridView}
        >
          <span className="layout-switcher__icon" aria-hidden="true">
            <GridView />
          </span>
        </button>
        <button
          className={classNames('layout-switcher__btn layout-switcher__btn--radius', {
            'layout-switcher__btn--active': !isGridView,
          })}
          type="button"
          aria-label={listViewLabelText}
          onClick={handleListView}
        >
          <span
            className="layout-switcher__icon layout-switcher__icon--transform"
            aria-hidden="true"
          >
            <ListView />
          </span>
        </button>
      </div>
    </div>
  );
};

export default LayoutSwitcher;
