import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import ArticlesListing from 'components/ArticlesListing';
import Breadcrumbs from 'components/Breadcrumbs';
import FeaturedArticle from 'components/FeaturedArticle';
import SimpleBanner from 'components/SimpleBanner';

import InsightsLandingBodyRenderer from './InsightsLandingBodyRenderer';
import { InsightsLandingPageTypes } from './models';

import './InsightsLandingPage.scss';

const InsightsLandingPage: FC<InsightsLandingPageTypes.InsightsLandingPageType> = ({
  data: { commonFields, insightsLandingPage, allArticlePage },
}) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    insightsLandingPage.seo;
  const {
    pageId,
    level,
    lang,
    title,
    banner,
    featuredTagText,
    popularTopics,
    helperFields,
    numberOfArticlesPerLoad,
    bottomModules,
    url,
  } = insightsLandingPage;
  const { edges } = allArticlePage;
  const { articleReadingTimeText, articleWrittenByText } = commonFields;

  return (
    <Layout className="articles">
      <Seo
        {...{
          seo: insightsLandingPage.seo,
          openGraph: insightsLandingPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className="banners">
        <Breadcrumbs level={level} />
        <SimpleBanner {...banner} />
      </div>

      <FeaturedArticle
        article={edges[0]?.node}
        featuredTagText={featuredTagText}
        lang={lang}
        readingTimeText={articleReadingTimeText}
        writtenByText={articleWrittenByText}
      />

      <ArticlesListing
        pageId={pageId}
        numberOfArticlesPerLoad={numberOfArticlesPerLoad}
        {...helperFields}
        popularTopics={popularTopics}
        lang={lang}
        readingTimeText={articleReadingTimeText}
        writtenByText={articleWrittenByText}
      />

      <div id="modules">
        <InsightsLandingBodyRenderer modules={bottomModules} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String, $pageId: Int) {
    commonFields {
      articleReadingTimeText
      articleWrittenByText
    }
    insightsLandingPage(url: { eq: $url }) {
      pageId
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentSimpleBanner
      }
      featuredTagText
      popularTopics {
        ...FragmentPopularTopics
      }
      helperFields {
        ...FragmentArticlesFiltersExtendedHelperFields
      }
      numberOfArticlesPerLoad
      bottomModules {
        ...FragmentInsightsLandingModules
      }
    }
    allArticlePage(
      filter: { landingPageId: { eq: $pageId }, isArticleFeatured: { eq: true } }
      sort: { fields: creationDate, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          ...FragmentFeaturedArticle
        }
      }
    }
  }
`;

export default InsightsLandingPage;
