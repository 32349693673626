import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { connectSortBy } from 'react-instantsearch-dom';
import classNames from 'classnames';

import Arrow from '../icons/dropdownArrow.svg';
import { SortingHitsProps } from './models';

import './SortingHits.scss';

const SortBy = ({ items, refine, sortByText }) => {
  const [active, setActive] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>(items[0]?.label);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLLIElement[]>([]);
  const btnRef = useRef<HTMLButtonElement>(null);

  const onDropdown = useCallback(() => {
    if (active) {
      setActive(false);
    } else {
      setActive(true);
      optionsRef.current[0].focus();
    }
  }, [active, optionsRef]);

  const handleOptionClick = useCallback((label, value) => {
    refine(value);
    setActive(false);
    setSortBy(label);
  }, []);

  const handleItemKeyDown = useCallback(
    (e, label, value) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        handleOptionClick(label, value);
        if (btnRef.current) btnRef.current.focus();
      }
    },
    [btnRef.current]
  );

  useEffect(() => {
    setSortBy(items.filter((item) => item.isRefined)[0].label);
  }, [items]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (active && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setActive(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [active]);

  const handleKeyDown = useCallback(
    (e) => {
      if (!e.shiftKey && e.keyCode === 9) {
        if (
          active &&
          dropdownRef.current &&
          optionsRef.current &&
          optionsRef.current[optionsRef.current.length - 1] === e.target
        ) {
          setActive(false);
        }
      }

      if (e.shiftKey && e.keyCode === 9) {
        if (active && dropdownRef.current && btnRef.current === e.target) {
          setActive(false);
        }
      }

      if (e.keyCode === 40) {
        e.preventDefault();
        if (active && dropdownRef.current && optionsRef.current && e.target.nextSibling) {
          e.target.nextSibling.focus();
        }
      }

      if (e.keyCode === 38) {
        e.preventDefault();
        if (active && dropdownRef.current && e.target.previousSibling) {
          e.target.previousSibling.focus();
        }
      }
    },
    [active, dropdownRef.current, optionsRef.current]
  );

  return (
    <div className="sort-by">
      <div className="sort-by__holder">
        <span className="sort-by__text breadcrumb">{sortByText}</span>
        <div
          ref={dropdownRef}
          role="presentation"
          onKeyDown={handleKeyDown}
          className={classNames('filters__refinement', {
            'filters__refinement--active': active,
          })}
        >
          <div className="filters__refinement-dropdown">
            <button
              className="filters__refinement-btn"
              aria-expanded={active}
              type="button"
              onClick={onDropdown}
              ref={btnRef}
            >
              <span className="breadcrumb">{sortBy}</span>
              <span className="filters__refinement-icon" aria-hidden="true">
                <Arrow className="filters__refinement-pic" />
              </span>
            </button>
            <ul
              role="tablist"
              tabIndex={-1}
              className={classNames('filters__refinement-list', {
                'filters__refinement-list--active': active,
              })}
              aria-label={sortByText}
            >
              {items?.map((item, i) => (
                <li
                  role="tab"
                  key={item.label}
                  className={classNames('breadcrumb filters__refinement-item', {
                    'filters__refinement-item--active': item.isRefined,
                  })}
                  tabIndex={active ? 0 : -1}
                  onClick={() => {
                    handleOptionClick(item.label, item.value);
                  }}
                  onKeyDown={(e) => {
                    handleItemKeyDown(e, item.label, item.value);
                  }}
                  ref={(btn: HTMLLIElement) => {
                    optionsRef.current[i] = btn;
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomSortBy = connectSortBy(SortBy);

const indexName = process.env.GATSBY_ALGOLIA_INDEX as string;

const SortingHits: FC<SortingHitsProps> = ({ sortByText, firstValue, secondValue }) => (
  <CustomSortBy
    sortByText={sortByText}
    defaultRefinement={`${indexName}-articles`}
    items={[
      { value: `${indexName}-articles`, label: firstValue },
      { value: `${indexName}-articles-date-asc`, label: secondValue },
    ]}
    transformItems={(items) =>
      items.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }

        return 0;
      })
    }
  />
);

export default SortingHits;
