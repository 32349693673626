import React, { FC } from 'react';
import { Link } from 'gatsby';

import { Col, Container, Row } from 'layout';
import ArticlePreviewFeaturedImage from 'common/images/ArticlePreviewFeaturedImage';
import NewsGridAuthors from 'common/NewsGrid/NewsGridAuthors';
import NewsGridInformation from 'common/NewsGrid/NewsGridInformation';
import NewsGridTags from 'common/NewsGrid/NewsGridTags';

import { FeaturedArticleProps } from './models';

import './FeaturedArticle.scss';

const FeaturedArticle: FC<FeaturedArticleProps> = ({
  article,
  featuredTagText,
  lang,
  readingTimeText,
  writtenByText,
}) => {
  if (!article) return null;

  const {
    articleType,
    creationDate,
    readingTime,
    title,
    previewText,
    articleTags,
    authors,
    previewImage,
  } = article;

  return (
    <section className="featured-article">
      <Container>
        <Row>
          <Col xl="5" className="featured-article__content col--no-gutters">
            <Link to={article.url} className="article__title-link" test-id="article-link">
              <span className="sr-only">{title}</span>
            </Link>
            <NewsGridInformation
              featured={featuredTagText}
              tagName={articleType.tagName}
              creationDate={creationDate}
              articleTypeColor={articleType.color}
              readingTime={readingTime}
              readingTimeText={readingTimeText}
              lang={lang}
            />
            <h2 className="featured-article__title h4">{title}</h2>
            <p className="featured-article__desc caption">{previewText}</p>
            <NewsGridTags article={articleTags} />
            {authors.length ? (
              <NewsGridAuthors authors={authors} writtenByText={writtenByText} />
            ) : null}
          </Col>
          {previewImage ? (
            <Col xl="7" className="featured-article__image col--no-gutters">
              <ArticlePreviewFeaturedImage imageData={previewImage} alt={previewImage.altText} />
            </Col>
          ) : null}
        </Row>
      </Container>
    </section>
  );
};

export default FeaturedArticle;
